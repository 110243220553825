(function(wnd) {
    function HeaderCountdown(container, params) {
        try {
            if (!container || (container && container.length == 0)) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            
        }
    }

    HeaderCountdown.prototype = {
        container: undefined,
        hasCountdown: false,
        countdownContainer: undefined,
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined,
        total: undefined,
        remainingTime: {
            days:0,
            hours:0,
            minutes:0,
            seconds:0,
        },
        countdownInterval: undefined,
        hasAnimation: false,
        closeBtn: undefined,
        closed: false,
        animationTime: 50,
        init: function() {            
            // return false;
            this.setElements();
            this.initCountdown();
            this.addDOMEvents();
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.closeBtn && this.closeBtn.length > 0) {
                this.closeBtn.click(function(){
                    _self.closeHelloBar();
                });
            }
        },
        setElements: function() {
            this.closeBtn = this.container.find('[data-purpose="close-hello-bar"]');
            this.countdownContainer = this.container.find('[data-purpose="countdown-container"]');
            if (this.countdownContainer && this.countdownContainer.length > 0) {
                this.hasCountdown = true;
                this.days = this.countdownContainer.find('.block-time.days .figure');
                this.hours = this.countdownContainer.find('.block-time.hours .figure');
                this.minutes = this.countdownContainer.find('.block-time.minutes .figure');
                this.seconds = this.countdownContainer.find('.block-time.seconds .figure');
            }
        },
        setParams: function(params) {
            if (params.remainingTime) this.setRemainingTime(params.remainingTime);
            if (typeof params.hasAnimation !== 'undefined') {
                this.hasAnimation = params.hasAnimation;
            }
            if (params.total) this.setTotal(params.total);
        },
        setRemainingTime: function(remainingTime) {
            this.remainingTime = $.extend({}, this.remainingTime = remainingTime);
        },
        setTotal: function(total) {
            this.total = total;
        },
        initCountdown: function() {
            console.log(this.hasAnimation);
            if (this.hasCountdown) {
                var _self = this;
                
                var day_1 = undefined;
                var day_2 = undefined;
                var day_3 = undefined;
                var hour_1 = this.hours.eq(0);
                var hour_2 = this.hours.eq(1);
                var minutes_1 = this.minutes.eq(0);
                var minutes_2 = this.minutes.eq(1);
                var seconds_1 = this.seconds.eq(0);
                var seconds_2 = this.seconds.eq(1);
                
                if (this.days && this.days.length > 0) {
                    day_1 = this.days.eq(0);
                    day_2 = this.days.eq(1);
                    day_3 = this.days.eq(2);
                }

                this.countdownInterval = setInterval(function(){
                    if (_self.total > 0) {
                        if (_self.hasAnimation) {
                            var total = _self.total;
                            
                            var hourTime = 60 * 60;
                            var dayTime = 24 * hourTime;
                            var days = Math.floor(total / dayTime);
                            var hours = Math.floor((total - days * dayTime) / hourTime);
                            var minutes = Math.floor((total - days * dayTime - hours * hourTime) / 60);
                            
                            _self.remainingTime = {
                                days: days,
                                hours: hours,
                                minutes: minutes,
                                seconds: total - days * dayTime - hours * hourTime - minutes * 60
                            };
                            
                            _self.checkTimeWithAnimation();
                        } else {
                            --_self.remainingTime.seconds;
                            
                            if (_self.remainingTime.minutes >= 0 && _self.remainingTime.seconds == 0) {
                                _self.remainingTime.seconds = 59;
                                --_self.remainingTime.minutes;
                            }

                            if (_self.remainingTime.hours >= 0 && _self.remainingTime.minutes < 0) {
                                _self.remainingTime.minutes = 59;
                                --_self.remainingTime.hours;
                            }

                            if (_self.remainingTime.days >= 0 && _self.remainingTime.hours < 0) {
                                _self.remainingTime.hours = 23;
                                --_self.remainingTime.days;
                            }

                            if (_self.hasAnimation) {
                                _self.checkTimeWithAnimation();
                            } else {
                                if (day_1 && day_2 && day_3) {
                                    _self.checkTime(_self.remainingTime.days, day_1, day_2, day_3)
                                }

                                _self.checkTime(_self.remainingTime.hours, hour_1, hour_2);

                                _self.checkTime(_self.remainingTime.minutes, minutes_1, minutes_2);

                                _self.checkTime(_self.remainingTime.seconds, seconds_1, seconds_2);

                            }
                        }
                        
                        --_self.total;
                    } else {
                        clearInterval(_self.countdownInterval);
                    }
                }, 1000);
            }
        },
        checkTime: function(value, item1, item2, item3) {
            var val_1 = value.toString().charAt(0);
            var val_2 = value.toString().charAt(1);
            var fig_1 = item1.find('.top').html();
            var fig_2 = item2.find('.top').html();            
            
            if (value >= 100 && item3) {
                var val_3 = value.toString().charAt(2);
                var fig_3 = item3.find('.top').html();
                if (fig_3 !== val_3) {
                    this.animateFigure(item3, val_3);
                }
            } else if (value >= 10) {
                if (fig_1 !== val_1) {
                    this.animateFigure(item1, val_1);
                }

                if (fig_2 !== val_2) {
                    this.animateFigure(item2, val_2);
                }
            } else {
                if (fig_1 !== '0') {
                    this.animateFigure(item1, 0);
                }
                if (fig_2 !== val_1) {
                    this.animateFigure(item2, val_1);
                }
            }
        },
        checkTimeWithAnimation: function() {
            var keys = Object.keys(this.remainingTime);
            for (var i = 0; i < keys.length; i++) {
                var items = typeof this[keys[i]] !== 'undefined' ? this[keys[i]] : null;
                if (items && items.length > 0) {
                    var first = items.get(0);
                    var nextValue = parseInt(this.remainingTime[keys[i]]);
                    
                    var changed = false;
                    var parent = $(first).parent();
                    var current = parseInt(parent.data('value'));
                    parent.data('value', this.remainingTime[keys[i]]);

                    if (parseInt(current) !== parseInt(nextValue)) {
                        changed = true;
                        // var nextValue = current - 1;
                        // nextValue = this.remainingTime[keys[i]] - 1;
                        // console.log(current, nextValue);
                        // changed = true;
                        // if (nextValue < 0) {
                        //     switch (keys[i]) {
                        //         case 'seconds':
                        //             nextValue = 59;
                        //             break;
                        //         case 'minutes':
                                    
                        //             if (this.remainingTime.hours >= 0 && this.remainingTime.seconds == 0) {
                        //                 nextValue = 59;
                        //             }
                        //             break;
                        //         case 'hours':
                        //             if (this.remainingTime.days >= 0) {
                        //                 nextValue = 23;
                        //             }
                        //             break;
                        //         case 'days':
                        //             nextValue = 0;
                        //             changed = false;
                        //             break;
                        //     }
                        // }

                        
                        if (changed) {
                            if (current < 10) {
                                current = '0'+current;
                            }
                            if (nextValue < 10) {
                                nextValue = '0'+nextValue;
                            }
                            parent.removeClass('flip');
                            for (var j = 0; j <= items.length; j++) {
                                if ($(items[j]).hasClass('current')) {
                                    $(items[j]).html(current);
                                } else {
                                    $(items[j]).html(nextValue);
                                }
                            }

                            
                            
                            this.animateNumber(parent);
                        } else if (parent && parent.length > 0) {
                            parent.removeClass('flip');
                        }
                    }
                }
            }

            
        },
        animateNumber: function(item) {
            item.removeClass('flip');
                setTimeout(function(){
                    item.addClass('flip');
                }, this.animationTime);
        },
        animateFigure: function(item, value) {
            if (this.hasAnimation) {
                var top = item.find('.top');
                var top_back = item.find('.top-back');
                var bottom = item.find('.bottom');
                var bottom_back = item.find('.bottom-back');

                top_back.find('span').html(value);
                bottom_back.find('span').html(value);

                TweenMax.to(top, 0.8, {
                    rotationX:'-180deg',
                    transformPerspective:300,
                    ease: Quart.easeOut,
                    onComplete: function() {
                        top.html(value);
                        bottom.html(value);
                        TweenMax.set(top, {rotationX:0})
                    }
                });

                TweenMax.to(top_back, 0.8, {
                    rotationX: 0,
                    transformPerspective: 300,
                    ease: Quart.easeOut,
                    clearProps: 'all'
                });
            } else {
                item.html('<span>'+value+'</span>');
            }
        },
        closeHelloBar: function() {
            if (!this.closed) {
                this.closed = true;
                this.container.slideToggle();

                $.ajax({
                    url:ajax_controller+'/close_hello_bar',
                    dataType:'json',
                    type:'post',
                });
            }
        }
    };

    wnd.HeaderCountdown = HeaderCountdown;
})(window);
