(function(wnd){
    function TrainingForm(form, params) {
        try {
            if (!form || (form && form.length == 0)) {
                throw 'Form is required!';
            } else if (form.length > 1) {
                throw 'Form must be unique element!';
            } else if (!form.is('form')) {
                throw 'Form must be a form element!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    TrainingForm.prototype = {
        form:undefined,
        button:undefined,
        notyf: undefined,
        sendedForm: false,
        topContent: undefined,
        bottomContent: undefined,
        confirmContent: undefined,
        dateField: undefined,
        selectors: {
            button: '[data-purpose="send-form"]',
            topContent: '[data-purpose="top-content"]',
            bottomContent: '[data-purpose="bottom-content"]',
            confirmContent: '[data-purpose="confirm-content"]',
        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find(this.getSelector('button'));
            this.topContent = this.form.find(this.getSelector('topContent'));
            this.bottomContent = this.form.find(this.getSelector('bottomContent'));
            this.confirmContent = this.form.find(this.getSelector('confirmContent'));            
            
            this.notyf = new Notyf({
                delay: 4000
            });
        },
        addDOMEvents: function() {
            var _self = this;
            this.button.click(function(){
                _self.sendForm();
            });

            this.form.on('submit', function(e){
                e.preventDefault();
                _self.sendForm();
            });

            $(document).on('closed', '.training-remodal', function(){
                _self.confirmContent.hide();
                _self.topContent.show();
                _self.bottomContent.show();
            });           
        },
        setParams: function(params) {

        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;
                var datas = {
                    lang: frontend_lang
                };

                this.form.serializeArray().forEach(function(field){
                    datas[field.name] = field.value
                });

                $.ajax({
                    url:this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data: datas,
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            // _self.notyf.confirm(response.msg);
                            _self.resetForm();
                        } else {
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(name, text){
                                    if (text && text.trim() !== '') {
                                        _self.notyf.alert(text);
                                    }

                                    var field = _self.form.find('[name="'+name+'"]');
                                    if (field.length > 0) {
                                        if (field.is('select')) {
                                            field.parent().addClass('has-error');
                                        } else {
                                            field.addClass('has-error');
                                        }
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function() {
                            _self.sendedForm = false;
                        }, 2000);
                    }
                });
            }
        },
        resetForm: function() {
            this.bottomContent.hide();
            this.topContent.hide();
            this.confirmContent.show();
            
            this.form.find('input[type="text"]').val('');
            this.form.find('textarea').val('');
            this.form.find('input[type="checkbox"]').prop('checked', false);
            this.form.find('select').val('');
            this.form.find('select').parent().addClass('without-value');
        },
        getSelector: function(key) {
            if (typeof this.selectors[key] === 'undefined') {
                throw key+' selector is undefined!';
            }

            return this.selectors[key];
        },
        isMobileBrowser: function() {
            var check = false;
            var check = false;
            (function(a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
                }
            )(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }
    };

    wnd.TrainingForm = TrainingForm;
})(window);
